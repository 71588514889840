body {
  margin: 0;
  padding: 0;
  font-family: monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html, body {margin: 0; height: 100%; overflow: hidden}


body {
  background-color: #2c3e50;
  font-family:'Raleway',  'open sans';
  font-weight: lighter;
}

#root {
  height:100vh;
}

.draw-container {
  height:100vh;
}

.logo {
  position: absolute;
  top: 40%;
  left: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */ 
}
.logo img {
  width: 500px;
}

@media only screen and (max-width: 650px){
  .logo img {
    width: 400px;
  }
}
@media only screen and (max-width: 450px){
  .logo img {
    width: 350px;
  }
}
